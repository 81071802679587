import {
    useLazyGetAssociationCollectionQuery,
    useLazyGetColormapCollectionQuery,
} from '@api/colormap/dist/enhancedColormapClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import type { AttributeColormapAssociationMap } from '@local/webviz/dist/components/Properties/PropertiesDialog';
import { useBaseXyz } from '@local/webviz/dist/context';
import { createSnapshotToUpdateColormapAssociations } from '@local/webviz/dist/context/snapshots/colorMapping';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'src/store/store';
import { updateObjectAttributeColormapAssociations } from 'src/store/visualization/visualizationSlice';

export function useAttributeColormapAssociation() {
    const { evouiEnableColormapsApi } = useFlags();
    const { getEntityState, setStateFromSnapshot } = useBaseXyz();
    const dispatch = useAppDispatch();

    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const [fetchAssociationCollection] = useLazyGetAssociationCollectionQuery();
    const [fetchColormaps] = useLazyGetColormapCollectionQuery();

    async function loadWorkspaceColormaps() {
        if (!evouiEnableColormapsApi) {
            return null;
        }
        try {
            const colormapList = await fetchColormaps({
                orgId,
                workspaceId,
                'api-preview': 'opt-in',
            }).unwrap();
            return colormapList;
        } catch (error) {
            trackError(
                `Error loading colormaps for the workspace: ${workspaceId}`,
                JSON.stringify(error),
            );
            return null;
        }
    }
    async function loadAssociationCollection(objectId: string) {
        if (!evouiEnableColormapsApi) {
            return null;
        }
        try {
            const associationList = await fetchAssociationCollection({
                orgId,
                workspaceId,
                objectId,
                'api-preview': 'opt-in',
            }).unwrap();
            return associationList;
        } catch (error) {
            trackError(
                `Error loading association collection for objectId: ${objectId}`,
                JSON.stringify(error),
            );
            return null;
        }
    }

    async function handleAttributeColormapAssociations(objectId: string) {
        if (!objectId) {
            return;
        }

        const colormapsList = await loadWorkspaceColormaps();
        const associationsList = await loadAssociationCollection(objectId);

        if (!colormapsList || !associationsList) {
            return;
        }

        const { colormaps } = colormapsList;
        const { associations } = associationsList;

        const attributeColormapAssociationMap = associations.reduce(
            (
                acc: AttributeColormapAssociationMap,
                { colormap_id: colormapId, attribute_id: attributeId },
            ) => {
                const colormap = colormaps.find(({ id }) => id === colormapId);
                if (colormap) {
                    acc[attributeId] = acc[attributeId] ?? [];
                    const duplicateColormap = acc[attributeId].find(({ id }) => id === colormap.id);
                    if (!duplicateColormap) {
                        acc[attributeId].push(colormap);
                    }
                }
                return acc;
            },
            {},
        );

        dispatch(
            updateObjectAttributeColormapAssociations({
                objectId,
                attributeAssociationMap: attributeColormapAssociationMap,
            }),
        );

        const snapshot = createSnapshotToUpdateColormapAssociations(
            attributeColormapAssociationMap,
            getEntityState,
        );
        setStateFromSnapshot(snapshot);
    }

    return { handleAttributeColormapAssociations };
}
