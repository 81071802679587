import { useLazyGetColormapCollectionQuery } from '@api/colormap/dist/enhancedColormapClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function useColormapLoader() {
    const [fetchColormaps] = useLazyGetColormapCollectionQuery();

    const { evouiEnableColormapsApi } = useFlags();
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    useEffect(() => {
        async function loadColormapsByOrgId() {
            try {
                await fetchColormaps({ orgId, workspaceId, 'api-preview': 'opt-in' }).unwrap();
            } catch (error) {
                trackError(
                    `Error loading colormaps for the workspace: ${workspaceId}`,
                    JSON.stringify(error),
                );
            }
        }
        if (evouiEnableColormapsApi) {
            loadColormapsByOrgId();
        }
    }, []);
}
