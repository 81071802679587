import { ColormapSchemaTypeEnum } from '@api/colormap/dist/constants';
import type {
    AttributeAssociationData,
    ContinuousColormapResponse,
    DiscreteColormapResponse,
} from '@api/colormap/dist/enhancedColormapClient';
import {
    usePostAssociationMutation,
    usePostColormapMutation,
} from '@api/colormap/dist/enhancedColormapClient';
import { trackError } from '@local/metrics/';
import type { ColormapSaveData } from '@local/webviz/dist/components/Properties/Colormaps/ArtifactColorMapsControl/ColormapCreatorControl/ColormapCreatorControl.types';
import { useBaseXyz } from '@local/webviz/dist/context';
import {
    generateContinuousColormapSnapshot,
    generateDiscreteColormapSnapshot,
} from '@local/webviz/dist/context/snapshots/colorMapping';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { getObjectIdFromViewId } from '../snapshots/generateSnapshot';
import { useAttributeColormapAssociation } from './useAttributeColormapAssociation';

export function useColormapCreator() {
    const { setStateFromSnapshot, getEntityState } = useBaseXyz();
    const [postColormap] = usePostColormapMutation();
    const [postAssociation] = usePostAssociationMutation();
    const { handleAttributeColormapAssociations } = useAttributeColormapAssociation();

    const [isPosting, setIsPosting] = useState(false);

    const { evouiEnableColormapsApi } = useFlags();
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const createColormap = async ({ colormapData, viewId, attributeId }: ColormapSaveData) => {
        if (!evouiEnableColormapsApi) {
            return '';
        }
        try {
            setIsPosting(true);
            const response = await postColormap({
                orgId,
                workspaceId,
                colormapData,
                'api-preview': 'opt-in',
            });

            if (response && 'data' in response) {
                const { data } = response;
                if (data && 'id' in data) {
                    const { id: colormapId, schema } = data;

                    // sync xyz state with new colormap created
                    let snapshot = {};
                    switch (schema) {
                        case ColormapSchemaTypeEnum.Continuous:
                            snapshot = generateContinuousColormapSnapshot(
                                data as ContinuousColormapResponse,
                                getEntityState,
                            );
                            break;
                        case ColormapSchemaTypeEnum.Discrete:
                            snapshot = generateDiscreteColormapSnapshot(
                                data as DiscreteColormapResponse,
                            );
                            break;
                        default:
                            break;
                    }
                    setStateFromSnapshot(snapshot);

                    const objectId = getObjectIdFromViewId(viewId);
                    await createColormapAssociation(objectId, {
                        colormap_id: colormapId,
                        attribute_id: attributeId,
                    });
                    await handleAttributeColormapAssociations(objectId);
                    return colormapId;
                }
            }
        } catch (error) {
            trackError(
                `Error creating colormap or association for the workspace: ${workspaceId}`,
                JSON.stringify(error),
            );
            return '';
        } finally {
            setIsPosting(false);
        }
        return '';
    };

    const createColormapAssociation = async (
        objectId: string,
        attributeAssociationData: AttributeAssociationData,
    ) => {
        if (!evouiEnableColormapsApi) {
            return;
        }
        try {
            await postAssociation({
                orgId,
                workspaceId,
                attributeAssociationData,
                'api-preview': 'opt-in',
                objectId,
            });
        } catch (error) {
            trackError(
                `Error creating colormap association for the workspace: ${workspaceId}`,
                JSON.stringify(error),
            );
        }
    };

    return { isPosting, createColormap };
}
